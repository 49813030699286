<template>
  <div class="overall">
    <div class="top-middle">
      <div class="top-content-father">
        <van-image :src="require('@/assets/img/exchange-background.png')"/>
        <div class="top-content">
          <div class="my-score">积分总数</div>
          <div class="max-value">
            {{ maxValue }}<span class="score">分</span>
          </div>
          <div class="detail-background" @click="ToIntegralDetail">
            积分明细
          </div>
        </div>
        <div class="top-content-middle">
          <div class="rest-score">可用积分</div>
          <div class="rest-value">
            {{ hasCount }}<span class="rest-number">分</span>
          </div>
          <div class="detail-background"  @click="collectPrize" >
            领取奖品
          </div>
        </div>
        <div class="integral-rule-group" @click="ruleShow = true">
          <div class="integral-rule">
            积分规则
          </div>
          <van-image class="question-img" :src="require('@/assets/img/question.png')"/>
        </div>
      </div>
      <div class="van-bar" v-if="outDataTipShow">
        <van-notice-bar
            left-icon="volume-o"
            :text="outDataTip"
            background="white"
            :scrollable="true"
            color="red"
        />
      </div>
      <div class="content-middle">

        <div class="list-for-group">
          <div class="list-for" v-for="(item, index) in dataList">
            <div class="img-border">
              <van-image class="img-path" :src="item.showFilePath" />
            </div>
            <div class="item-name">
              {{ item.name }}
            </div>
            <div class="item-needValue">
              <span style="color: #FDC067">{{ item.needValue }}</span>积分/件
            </div>
            <van-stepper class="item-stepper" min="0" :value="item.chooseNum" :disabled="outDataTipShow"
                         @plus="plus(item,index)" @minus="minus(item,index)" :disable-input="true"/>
          </div>

          <div class="space"></div>
          <div class="space"></div>

        </div>
      </div>
    </div>

    <div class="bottom-father">
      <div class="bottom-left">
        <div style="display: inline-block">
          <span>你已选择 {{ count }} 件产品,共计 <span class="bottom-left-score">{{ needValue }}</span> 积分</span>
        </div>
        <van-button round type="info" class="bottom-button" :disabled="outDataTipShow || needValue === 0"
                    @click="soonExchange">
          <span v-if="orderStatus === 0">查看订单</span><span v-if="orderStatus=== 1">保存订单</span>
        </van-button>
      </div>
    </div>

    <!--    遮罩框-->
    <!--    积分不足-->
    <van-overlay :show="deficencyShow" :lock-scroll="false">
      <div @click.stop>
        <div class="dialog-content">
          <div class="dialog-x-close-dialog" > <van-image width="20px" @click="deficencyButton" :src="require('@/assets/img/x.png')"></van-image></div>
          <div class="dialog-text-father">
            <div style="color: red">抱歉！剩余积分不足</div>
            <div class="dialog-list">
              <div v-for="item in exchangeList">
                <div class="exchange-item ">{{ item.name }}</div>
                <div class="exchange-item-1">x{{ item.chooseNum }}</div>
                <div class="exchange-item-1">{{item.needValue}}</div>
              </div>
            </div>

          </div>
          <div class="dialog-value-need">
            当前奖品共需<span style="color: red">{{tempCount}}积分</span>

          </div>

          <div class="dialog-button-father">
            <van-button class="dialog-button" round size="large" type="info" @click="deficencyButton">
              确认</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--    即将兑换-->
    <van-overlay :show="soonShow" :lock-scroll="false">
      <div @click.stop>
        <div class="dialog-content">
          <div class="dialog-x-close-dialog" > <van-image width="20px" @click="closeDialog" :src="require('@/assets/img/x.png')"></van-image></div>
          <div class="dialog-text-father">
            <div style="color: red">您即将兑换</div>
            <div class="dialog-list">
              <div v-for="item in exchangeList">
                <div class="exchange-item ">{{ item.name }}</div>
                <div class="exchange-item-1">x{{ item.chooseNum }}</div>
                <div class="exchange-item-1">{{item.needValue}}</div>
              </div>
            </div>

          </div>
          <div class="dialog-value-need">
            总价值<span style="color: red">{{tempCount}}分</span>的奖品！

          </div>

          <div class="dialog-button-father">
            <van-button class="dialog-button2" round  @click="soonShow = false">
              取消</van-button>
            <van-button class="dialog-button2" round  type="info" @click="confirmButton">
              确认</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--    成功兑换-->
    <van-overlay :show="successShow" :lock-scroll="false">
      <div @click.stop>
        <div class="dialog-content">
          <div class="dialog-x-close-dialog" > <van-image width="20px" @click="closeDialog" :src="require('@/assets/img/x.png')"></van-image></div>
          <div class="dialog-text-father">
            <p style="color: red">恭喜！您已成功兑换</p>
          </div>
          <div class="dialog-van-img-father">
            <van-image class="dialog-van-img" :src="require('@/assets/img/gift.png')"></van-image>
          </div>
          <div class="dialog-record-text">
            请于指定时间到社区办事处凭<span style="color: red">兑换记录</span>领取奖品
          </div>
          <div class="dialog-button-father">
            <van-button class="dialog-button" round  type="info" @click="successButton">
              确认</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--    兑奖列表-->
    <van-overlay :show="prizeListShow" :lock-scroll="false">
      <div @click.stop>
        <div class="dialog-content">
          <div class="dialog-x-close-dialog"> <van-image width="20px" @click="closeDialog" :src="require('@/assets/img/x.png')"></van-image></div>
          <div class="dialog-text-father">
            <div style="color: red">兑换列表</div>
            <div class="dialog-list">
              <div v-for="item in exchangeList">
                <div class="exchange-item ">{{ item.name }}</div>
                <div class="exchange-item-1">x{{ item.chooseNum }}</div>
                <div class="exchange-item-1">{{item.needValue}}</div>
              </div>
            </div>
          </div>

          <div class="dialog-button-father">
            <van-button class="dialog-bottom-button" round @click="prizeListShow = false">
              取消</van-button>
            <van-button round type="info" @click="offlineCollectPrize" :disabled="exchangeList.length === 0">
              确定线下领取奖品</van-button>
          </div>
          <div class="dialog-confirm-text">
            <span style="color: red">点击确定后 即完成本次积分兑换！</span>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--    兑奖列表-->
    <van-overlay :show="orderShow" :lock-scroll="false">
      <div @click.stop>
        <div class="dialog-content">
          <div class="dialog-x-close-dialog" > <van-image width="20px" @click="closeDialog" :src="require('@/assets/img/x.png')"></van-image></div>
          <div class="dialog-text-father">
            <div style="color: red">兑换列表</div>
            <div class="dialog-list">
              <div v-for="item in exchangeList">
                <div class="exchange-item ">{{ item.name }}</div>
                <div class="exchange-item-1">x{{ item.chooseNum }}</div>
                <div class="exchange-item-1">{{item.needValue}}</div>
              </div>
            </div>
          </div>

          <div class="dialog-button-father">
            <van-button class="dialog-button" round size="large" type="info" @click="goBack">
              返回</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--    兑奖完成-->
    <van-overlay :show="finishShow" :lock-scroll="false">
      <div @click.stop>
        <div class="dialog-content-success">
          <div class="dialog-x-close-dialog" > <van-image width="20px" @click="closeDialog" :src="require('@/assets/img/x.png')"></van-image></div>
          <div class="dialog-text-father">
            <div>兑奖完成</div>
            <div class="dialog-success-text"><span style="color: red;">{{exchangeUserName}}</span>您好，您于<span style="color: red;">{{exchangeTime}}</span>
              兑换成功，请于社区领取奖品</div>
            <div class="dialog-list">
              <div v-for="item in resultList">
                <div class="exchange-item ">{{ item.name }}</div>
                <div class="exchange-item-1">x{{ item.chooseNum }}</div>
                <div class="exchange-item-1">{{item.needValue}}</div>
              </div>
            </div>
          </div>
          <div class="dialog-button-father">
            <van-button class="dialog-button" round size="large" type="info" @click="goBack">
              返回</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--    积分规则-->
    <van-overlay :show="ruleShow" :lock-scroll="false">
      <div @click.stop>
        <div class="dialog-content-rule">
          <div class="dialog-x-close-dialog" >
            <van-image width="20px" @click="closeDialog" :src="require('@/assets/img/x.png')"></van-image>
          </div>
          <div class="dialog-text-father">
            <div>积分规则</div>
            <div class="dialog-rule-text">
              <div>一、积分获得方式：</div>
              <div>1、居民可以通过居民果果端“民生小事”参与村庄治理，将周边不合理的事项上报平台，如被采纳每条可获得10个积分；</div>
              <div>2、居民参加村级组织的各种线下、义工活动，每次可获得10个积分；</div>
              <div> 3、居民可申请加入本村新时代文明志愿者队伍，申请成为线上服务提供者，完成系统下派的老人服务获得积分，每次完成可获得20个积分。</div>
              <div>二、积分兑换时间：12月10日--12月31日</div>
              <div>三、积分兑换方式：居民可以根据现有积分在平台上确认兑换的礼品，礼品将在1月1日--1月15日内发放到位。</div>
            </div>
          </div>
          <div class="dialog-button-father">
            <van-button class="dialog-button" round size="large" type="info" @click="goBack">
              确认</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterDateTime} from "@/utils/utils";
import {newDate} from "../../utils/utils";

export default {
  name: "integral-change",
  components: {
    topBar
  },
  data() {
    return{
      ruleShow: false,
      count: 0,
      tempCount: 0,
      needValue: 0,
      maxValue: 0,
      hasCount: 0,
      //判断积分
      disabledButton: false,
      dataList:[],
      list:[],
      chooseList: [],
      //订单状态 0-查看订单 1-保存订单
      orderStatus: 0,
      soonShow: false,
      deficencyShow: false,
      successShow: false,
      prizeListShow: false,
      finishShow: false,
      orderShow: false,
      outDataTip: '',
      outDataTipShow: true,
      limit: -1,
      page: 0,
      //奖品状态 0-上架
      prizeStatus: 0,
      exchangeTime: '',
      createDate: '',
      exchangeList: [],
      status: 0,
      tempItem:{},
      resultList: [],
      exchangeUserName:'',
    }
  },
  mounted() {
    this.getDataList();
  },
  methods:{
    getDataList() {
      this.getMaxValue()
      this.getValidity()
      this.getPrizeList()
    },
    setChangeList(){
      this.exchangeList = []
      this.tempCount = 0
      this.dataList.forEach(item => {
        if(item.chooseNum !== 0){
          this.exchangeList.push(item)
          this.tempCount += item.needValue * item.chooseNum
        }
      })
    },
    soonExchange(){
      this.setChangeList()
      if(this.orderStatus === 0){
        this.orderShow = true
      }else{
        this.soonShow = true
      }
    },
    getExchangeList(){
      this.$httpApp({
        url: this.$http.adornUrl('/wxapp/prize/exchange/record/list'),
        method: "post",
        params: this.$http.adornParams({
          limit: this.limit,
          page: this.page,
          userId: this.$userId,
          orgId: this.$orgId,
          status: this.status
        })
      }).then(({data}) => {
        if(data && data.code === 0){
          this.dataList.forEach(item => {
            data.page.list.forEach(temp =>{
              if(temp.prizeId === item.id){
                item.chooseNum += temp.num
                this.needValue += item.needValue * temp.num
                this.count += temp.num
              }
            })
          })
          if(this.dataList.length > 0){
            this.plus(this.dataList[0],-1)
            this.deficencyShow = false
            this.orderStatus = 0
          }
        } else{
          this.$notify({type: 'danger', message: data.msg})
        }

      })
    },
    //奖品列表
    getPrizeList() {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/prize/list'),
        method: "post",
        params: this.$httpApp.adornParams({
          type: this.prizeStatus,
          limit: this.limit,
          page: this.page,
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.dataList = data.page.list
          this.getImgUrl()
          this.getExchangeList()
        }
      })
    },
    getImgUrl(){
      this.dataList.forEach((item,index) => {
        item.chooseNum = 0
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/getUrl'),
          method: "post",
          params: this.$http.adornParams({
            path: item.filePath,
          })
        }).then(({data}) => {
          if(data && data.code === 0){
            this.$set(this.dataList[index], 'showFilePath', data.objectUrl)
          }
        })
      })

    },
    //积分总值
    getMaxValue() {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/user/account/countByUserId'),
        method: "post",
        params: this.$httpApp.adornParams({
          userId:this.$userId,
          community: this.$orgId,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.maxValue = data.count + data.frozenAccount;
          this.hasCount = data.count
        } else {
          this.$notify({type:'danger', message:data.msg})
        }
      });
    },
    //有效期
    getValidity() {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/prize/validity/info'),
        method: "post",
        params: this.$httpApp.adornParams({
          community: this.$orgId,
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.outDataTip = data.outDataTip
          for(let i = 0; i < data.inDateJson.length; i++){
            let begin = newDate(data.inDateJson[i].beginDate)
            let end = newDate(data.inDateJson[i].endDate)
            let cur = new Date()
            if(cur.getTime() >= begin.getTime() && cur.getTime() <= end.getTime()) {
              this.outDataTipShow = false
            }
          }
        } else {
          this.$notify({type:'danger', message: data.msg})
        }
      });
    },
    //点击积分明细按钮跳转到积分流水列表
    ToIntegralDetail() {
      this.$router.push({path: './integral-detail'})
    },
    //点击领取奖品跳转奖品列表页面
    collectPrize() {
      this.exchangeList = []
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/prize/exchange/record/list'),
        method: 'post',
        params: this.$httpApp.adornParams({
          page: 0,
          limit: -1,
          userId: this.$userId,
          orgId: this.$orgId,
          status: 0
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          // this.exchangeList = data.page.list
          data.page.list.forEach(item =>{
            this.exchangeList.push({'name':item.prizeName,'needValue':item.count,'chooseNum':item.num})
          })
          this.exchangeUserName = data.page.list[0].name
        }
      })
      this.prizeListShow = true
    },
    //点击确定线下领取奖品按钮跳转到成功界面
    offlineCollectPrize() {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/user/account/verification'),
        method: 'post',
        params: this.$httpApp.adornParams({
          userId: this.$userId,
          community: this.$orgId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.resultList = this.exchangeList
          this.needValue = 0
          this.count = 0
          this.prizeListShow = false
          this.finishShow = true
          this.exchangeTime = formatterDateTime(new Date());

          this.getDataList()
        } else {
          this.$notify({type: 'danger', message: data.msg})
        }
      })
    },
    minus(item){
      this.orderStatus = 1
      this.count--;
      this.needValue -= item.needValue
      this.hasCount += item.needValue
      item.chooseNum--;
      // if(this.maxValue >= this.needValue){
      //   this.disabledButton = false;
      // }
    },
    plus(item, index){
      if(index === -1){
        return;
      }
      if(item === null){
        return
      }
      this.needValue += item.needValue
      if(item.needValue > this.hasCount){
        this.tempItem = item
        item.chooseNum++;
        this.tempCount = this.needValue
        this.setChangeList()
        this.deficencyShow = true
        this.needValue -= item.needValue
        return;
      }else{
        this.orderStatus = 1
        this.count++;
        item.chooseNum++;
        this.hasCount -=item.needValue
      }
    },
    //即将兑换确认
    confirmButton() {
      let tempList = []
      this.exchangeList.forEach(item => {
        tempList.push({prizeId: item.id, num: item.chooseNum})
      })
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/user/account/saveOrder'),
        method: "post",
        data: this.$httpApp.adornData({
          list: tempList,
          userId: parseInt(this.$userId),
          community: parseInt(this.$orgId)
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.soonShow = false
          this.successShow = true
          this.orderStatus = 0
          // this.$notify({type: 'success', message: '订单保存成功'})
        }else{
          this.soonShow = false
          this.$notify({type: 'danger', message: data.msg})
        }
      })
    },
    //积分不足确认
    deficencyButton() {
      this.closeDialog()
      this.tempItem.chooseNum--;
    },
    //成功兑换确认
    successButton() {
      this.closeDialog()
    },
    goBack() {
      this.closeDialog()
    },
    cancel() {
      this.closeDialog()
    },
    closeDialog() {
      this.soonShow = false
      this.successShow = false
      this.deficencyShow = false
      this.finishShow = false
      this.prizeListShow = false
      this.ruleShow = false
      this.orderShow = false
    },
  }
}
</script>

<style scoped>
.overall {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.top-middle {
  overflow-y: scroll;
}
.top-content-father {
  position: relative;
  top: 0;
  display: flex;
}
.top-content {
  position: absolute;
  left: 10%;
  top: 15%;
  color: #F5F5F5;
  font-size: 0.4rem;
}
.my-score {
  margin-bottom: 0.2rem;
}
.max-value {
  color: #FEFCFA;
  font-size: 0.6rem;
  margin-bottom: 1.1rem;
}
.score {
  font-size: 0.5rem;
}
.detail-background {
  border-radius: 0.5rem;
  width: 2.2rem;
  height: 1rem;
  background: rgba(255, 190, 103, 1);
  text-align: center;
  line-height: 1rem;
}
.top-content-middle {
  position:absolute;
  left: 40%;
  top: 15%;
  color: #F5F5F5;
  font-size: 0.4rem;
}
.rest-score {
  margin-bottom: 0.2rem;
  color: #A0510c;
}
.rest-value {
  font-size: 0.6rem;
  margin-bottom: 1.1rem;
  color: #A0510c;
}
.rest-number {
  font-size: 0.5rem;
}
.integral-rule-group {
  position: absolute;
  width: 30%;
  right: 0;
  text-align: right;
  margin-right: 5%;
  top: 15%;
}
.integral-rule {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.1rem;
  color: #F5F5F5;
  font-size: 0.4rem;
}
.question-img {
  vertical-align: middle;
  width: 34px;
  height: 34px
}
.content-middle {
  display: flex;
  flex: 1;
  overflow-y: scroll;
  margin-top: 0.2rem;
}
.list-for {
  width: 4rem;
  height: 6.5rem;
  margin-bottom: 0.2rem;
}
.img-border {
  border-radius: 0.2rem;
  border: 1px solid #CCD9E6;
  width: 4rem;
  height: 4rem;
  position: relative;
}
.img-path {
  position: absolute;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.list-for-group {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 9rem;
  margin: 0.2rem auto;
  /*height: 8rem;*/
  overflow-y: scroll;
}
.item-name {
  font-size: 0.4rem;
  font-weight: bold;
  margin: 0.2rem 0;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: clip;
}
.item-needValue {
  font-size: 0.3rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}
.item-stepper {
  button-size: 30px;
  input-width: 30px;

}
.item-stepper .van-stepper__minus {
  margin-right: 0.4rem;
}
.item-stepper .van-stepper__input {
  margin-right: 0.4rem;
}
.van-bar{
  margin: 0 0.7rem;
}
.bottom-father {
  height: 1.7rem;
  width: 100%;
  background: white;
  box-shadow: #9c9696 0 0 0.2rem;
  position: fixed;
  bottom: 0;
  display: flex;
}
.bottom-left {
  line-height: 1.7rem;
  font-size: 0.35rem;
  margin-left: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.bottom-left-score {
  font-size: 0.5rem;
  color: red;
}
.bottom-button {
  vertical-align: middle;
  margin-bottom: 0.1rem;
  margin: auto 0.5rem ;
}
.space {
  width: 4rem;
  height: 2.5rem;
}
.dialog-content {
  height: 9.8rem;
  width: 8rem;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
}
.dialog-x-close-dialog {
  font-size: 0.6rem;
  text-align: right;
  padding: 0.2rem 0.4rem 0 0
}
.dialog-text-father {
  font-size: 0.5rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
  line-height: 0.6rem;
}
.dialog-list {
  height: 5rem;
  padding: 0 8%;
  overflow-y: scroll;
  font-size: 0.4rem;
  margin-top: 0.2rem;
}
.exchange-item {
  display: inline-block;
  width: 40%;
}
.exchange-item-1 {
  width: 30%;
  display: inline-block;
}
.dialog-value-need {
  font-size: 0.4rem;
  font-weight: bold;
  text-align: center;
  margin-top: 0.4rem;
}
.dialog-button-father{
  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-around;
}
.dialog-button {
  width: 4rem;
}
.dialog-button2{
  width: 3rem;
}
.dialog-van-img-father {
  width: 100%;
  text-align: center;
}
.dialog-van-img {
  width: 5rem;
}
.dialog-record-text {
  width: 100%;
  text-align: left;
  padding: 0 1rem;
  font-size: 0.4rem;
  font-weight: bold;
  line-height: 0.5rem;
  margin: 0.7rem 0;
}
.dialog-bottom-button {
  width: 2.5rem;
}
.dialog-confirm-text {
  width: 100%;
  text-align: center;
  font-size: 0.3rem;
  font-weight: bold;
  line-height: 0.5rem;
  margin: 0.5rem 0;
}
.dialog-success-text {
  font-size: 0.35rem;
  font-weight: bold;
  margin-top: 0.2rem;
  padding: 0 0.7rem;
}
.dialog-content-success {
  height: 10.5rem;
  width: 8rem;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
}
.dialog-rule-text {
  font-size: 0.3rem;
  font-weight: normal;
  text-align: justify;
  line-height: 0.6rem;
  padding: 0 0.5rem;
  margin-top: 0.2rem;
  height: 7rem;
  width: 100%;
  /*overflow-y: auto;*/
  overflow-y: scroll;
}
.dialog-content-rule {
  height: 11rem;
  width: 8rem;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px
}
</style>
